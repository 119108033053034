// zoom-in-zoom-out animation
@keyframes zoom-in-zoom-out {
	0% {
		transform: scale(1, 1);
	}

	50% {
		transform: scale(1.05, 1.05);
	}

	100% {
		transform: scale(1, 1);
	}
}

.width--full {
	width: 100%;
}

.height--full {
	height: 100%;
}

.object-fit-cover {
	object-fit: cover;
}

.object-fit-contain {
	object-fit: contain;
}

.dsg-container {
	cursor: initial !important;

	.dsg-cell-header {
		background-color: #be3646;
		color: #ffffff;
	}

	.dsg-cell {
		&.dsg-cell-disabled {
			.dsg-input {
				color: grey;
			}
		}
	}
}

//for selected sidenav icon
.active-sidenav-icon {
	&--dsm {
		svg {
			path {
				fill: #ed0000;
				stroke: #ed0000;
			}
		}
	}

	&--fs {
		svg {
			path {
				fill: #ed0000;
			}
		}
	}

	&--business-plan {
		svg {
			path {
				stroke: #ed0000;
			}

			circle {
				fill: #ed0000;
			}

			line {
				stroke: #ed0000;
			}
		}
	}
}

//for subbchild nav ui
.child-item-list-wrapper {
	position: fixed;
	background-color: #ffff;
	left: 80px;
	border: 1px solid #0000;
}

.truncate--text {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.truncate-lines--2 {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.truncate-lines--3 {
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.sort-icon {
	height: 13px;
	width: 13px;
}

.powerbi-client-embed-container {
	height: calc(100vh - 121px);
}

.dashboard-title {
	position: absolute;
	bottom: 20px;
	color: #ffffff;
	font-size: 40px;
	text-align: right;
	width: 100%;
	padding-right: 20px;
}