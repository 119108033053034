body {
	margin: 0;
	font-family:
		'MMC',
		'Poppins',
		-apple-system,
		BlinkMacSystemFont,
		'Segoe UI',
		'Roboto',
		'Oxygen',
		'Ubuntu',
		'Cantarell',
		'Fira Sans',
		'Droid Sans',
		'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

*,
*::after,
*::before {
	box-sizing: border-box;
}

::-webkit-scrollbar {
	width: 8px;
	height: 10px;
}

::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
	cursor: pointer;
}

::-webkit-scrollbar-thumb {
	background-color: #ed0000;
	outline: 0;
}

input:-webkit-autofill {
	-webkit-text-fill-color: #000000;
	box-shadow: 0 0 0 50px #ffffff inset;
	caret-color: #000000;
}

input:-webkit-autofill:focus {
	box-shadow: 0 0 0 50px #ffffff inset;
	-webkit-text-fill-color: #000000;
}

/* Apply font-family to toast messages */
.Toastify__toast {
	font-family: inherit !important;
	font-size: 14px;
}